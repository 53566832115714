export default function ShipmentInformation({ country, state, city, street, zip }) {
  return (
    <div className="p-5 bg-gray-50 mt-5 mb-5 text">
      <span className="text-gray-400">Shipment Destination Address</span>
      <div className="">
        <div className="px-5 py-2">
          <div className="flex justify-between flex-wrap">
            <span className="p-3"><b>Country: </b>{country}</span>
            <span className="p-3"><b>State: </b>{state}</span>
            <span className="p-3"><b>City: </b>{city}</span>
          </div>
          <div className="flex justify-between flex-wrap w-40%">
            <span className="p-3"><b>Street: </b>{street}</span>
            <span className="p-3"><b>Zip Code: </b>{zip}</span>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}
