export default function SenderDetails({ name, phoneNo, email }) {
  return (
    <>
      <section className="flex flex-col items-end justify-end p-5">
        <span className="mb-5">Sender Information</span>
        <h2 className="font-bold text-3xl uppercase mb-1">{name}</h2>
        <p>{phoneNo}</p>
        <p>{email}</p>
      </section>
    </>
  )
}
