import * as yup from "yup";
import { useFormik} from "formik";
import "./Login.scss";
import { useEffect } from "react";
import { login } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
const dispatch = useDispatch();
const navigate = useNavigate();

    let schema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required(),
    });

    const formik = useFormik({
        initialValues: {
          username: "",
          password: ""
        },
        validationSchema: schema,
        onSubmit: (values) => {
          try {
            dispatch(login(values))
          } catch (error) {
            console.log(error)
          }
        }
    });

    const {user, isLoading, isError, isSuccess, message} = useSelector((state)=>state.auth);
    
    useEffect(()=>{
      if(isSuccess){
        navigate(0)
      } else{
        navigate("");
      }
    },[isSuccess, navigate])

  return (
    <div className="login">
      <div className="wrapper">
        <span className="heading">LOGIN</span>
        <form action="" onSubmit={formik.handleSubmit}>
            <div className="field">
                <label htmlFor="Username">Username</label>
                <input 
                    type="text"
                    name="username"
                    id="username"
                    autoComplete="off"
                    value={formik.values.username}
                    onChange={formik.handleChange("username")}
                    onBlur={formik.handleBlur("username")}
                    />
                
                <div className="error">
                { formik.touched.username && formik.errors.username ? (
                    <div>{formik.errors.username}</div>
                    ) : null }
                </div>
            </div>

            <div className="field">
                <label htmlFor="Password">Password</label>
                <input 
                    type="password"
                    name="password"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                    />
                
                <div className="error">
                { formik.touched.password && formik.errors.password ? (
                    <div>{formik.errors.password}</div>
                    ) : null }
                </div>
            </div>
            <button 
                className="loginButton"
                type="submit"
            >
            LOGIN
          </button>
        </form>
        { isLoading &&
        <span style={{ color: "#013869", fontWeight: "bold",  }}>Logging in please wait...</span>
        }
        { isError &&
        <span style={{ color: "red", fontWeight: "bold",  }}>Wrong Credentials. Try again</span>
        }
      </div>
    </div>
  )
}

export default Login
