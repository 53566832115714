import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import invoiceService from "./invoiceService";

export const createInvoices = createAsyncThunk(
    "invoices/",
    async(invoice, thunkAPI) => {
        try {
            await invoiceService.newInvoice(invoice)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const initialState = {
    createdInvoice: "",
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ""
}

export const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
       builder
       .addCase(createInvoices.pending, (state) => {
        state.isLoading = true;
       })
       .addCase(createInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createdInvoice = action.payload;
    })
    .addCase(createInvoices.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.createdInvoice = null;
        state.message = "Rejected"
    })
    }
})

export default invoiceSlice.reducer;