import "./Navbar.scss";
import { Link, useActionData, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";

const Navbar = () => {
    const user = localStorage.getItem("user");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        localStorage.removeItem("user")
        dispatch(logout())
        navigate(0)
    }
  
    return (
    <div className="navbar">
        <div className="wrapper">
            <ul>
                <li>
                    <Link className="link" to="/">Home</Link>
                </li>
                <li>
                    <Link className="link" to="/new-invoice">Invoice</Link>
                </li>
            </ul>
           {user === null &&
            <div className="buttonWrapper">
                <Link to="/new-invoice" className="loginLink">Login to Create Invoice</Link>
            </div>}
           { user &&
            <div className="buttonWrapper">
                <button onClick={handleLogout}>Logout</button>
            </div>}
        </div>
    </div>
  )
}

export default Navbar