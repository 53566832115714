import axios from "axios";
import { base_url } from "../../utils/base_url";

const getTokenFromLocalStorage = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
    const config = {
        headers : {
            authorization: `Bearer ${getTokenFromLocalStorage?.token}`,
            Accept: "application/json"
        }
    }
    
const newInvoice = async(invoice) => {
    const response = await axios.post(`${base_url}invoices`, config, invoice)
    return response.data
}

const invoiceService = {
    newInvoice
}

export default invoiceService