
const InvoiceData = ({invoiceNumber, invoiceDate}) => {
  return (
    <div className="p-5 bg-gray-50 mt-5 mb-5 text">
    <span className="text-gray-400">Invoice Information</span>
    <div className="">
        <div className="flex gap-10 flex-wrap w-40%">
          <span className="p-3"><b>Invoice Date: </b>{invoiceDate}</span>
          <span className="p-3"><b>Invoice Number: </b>{invoiceNumber}</span>
      </div>
      <div></div>
    </div>
  </div>
  )
}

export default InvoiceData