import "./Home.scss";
import hero from "../../images/screencapture.JPG";
import { Link } from "react-router-dom"

const Home = () => {
  return (
    <div className="home">
        <div className="wrapper">
            <div className="homeContent">
                <h1 className="heading">Go Express Shipment Invoice</h1>
                <p className="desc">
                    Create, download and print invoices for your clients. 
                </p>
                <Link className="loginBtn" to="/new-invoice">Login to Create Invoice</Link>
            </div>
            <div className="imgContainer">
                <img src={hero}
                alt="Create Shipment Invoice"
                className="img"
                />
            </div>
        </div>
    </div>
  )
}

export default Home
