import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Error from "./pages/error/Error";
import Home from "./pages/Home/Home";
import Invoice from "./pages/Invoice/Invoice";
import Login from "./pages/Login/Login";

const App = () => {
  const user = localStorage.getItem("user");
  return (
    <BrowserRouter>
    <Navbar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/new-invoice" element={user ? <Invoice /> : <Login />} />
            <Route path="*" element={<Error />}></Route>
        </Routes>
    </BrowserRouter>
  )
}

export default App
