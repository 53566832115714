export default function ReceiverDetails({ name, phoneNo, email }) {
  return (
    <>
      <section className="mt-0 p-5 border-b-2 border-gray-300">
        <span>Receiver Information</span>
        <h2 className="text-2xl uppercase font-bold mb-1">{name}</h2>
        <p>{phoneNo}</p>
        <p>{email}</p>
      </section>
    </>
  )
}
