import logo from "../../../images/lo.png"

export default function Header() {
  return (
    <>
      <header className="flex flex-col items-center justify-center mb-3 xl:flex-row xl:justify-between">
        <div className="flex items-center gap-3">
          <img width="20%" src={logo} alt="Go Express logo" />
          <h2 className="font-bold uppercase tracking-wide text-4xl mb-3">
            Go Express Shipment Invoice
          </h2>
        </div>
      </header>
    </>
  )
}
