import { useState, useRef, useEffect } from "react";
import "./Invoice.scss";
import * as yup from "yup";
import { useFormik} from "formik";
import ReceiverDetails from "./components/ReceiverDetails";
import ShipmentInformation from "./components/ShipmentInformation";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SenderDetails from "./components/SenderDetails";
import Notes from "./components/Notes";
import Table from "./components/Table";
import TableForm from "./components/TableForm";
import ReactToPrint from "react-to-print";
import InvoiceData from "./components/InvoiceData";


function Invoice() {
  const [width] = useState(641);
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  let schema = yup.object().shape({
      senderFullname: yup.string().required("Sender Name is Required"),
      senderPhoneNo: yup.string().required("Sender Phone Number is Required"),
      senderEmail: yup.string().email(),
      receiverFullname: yup.string().required("Receiver Name is Required"),
      receiverPhoneNo: yup.string().required("Receiver Phone Number is Required"),
      receiverEmail: yup.string().email(),
      country: yup.string().required("Country is Required"),
      state: yup.string().required("State is Required"),
      city: yup.string().required("City is Required"),
      street: yup.string().required("Street is Required"),
      zip: yup.number(),
      invoiceNo: yup.string().required("Invoice Number is Required"),
      invoiceDate: yup.date().required("Invoice Date is Required"),
      note: yup.string(),
    });

    const formik = useFormik({
        initialValues: {
          senderFullname: "",
          senderPhoneNo: "",
          senderEmail: "",
          receiverFullname: "",
          receiverPhoneNo: "",
          receiverEmail: "",
          country: "",
          state: "",
          city: "",
          street: "",
          zip: "",
          invoiceNo: "",
          invoiceDate: "",
          notes: ""
        },
        validationSchema: schema,
        onSubmit: (values) => {
          alert("invoice")
        }
    });

    let values = formik.values;

  const componentRef = useRef();


  useEffect(() => {
    if (window.innerWidth < width) {
      alert("Place your phone in landscape mode for the best experience");
    }
  }, [width]);


  return (
    <>
      <main
        className="m-5 p-5 xl:grid grid-cols-2 gap-10 xl:items-start"
        style={{
          maxWidth: "1920px",
          margin: "auto",
        }}
      >
        <section className="section1">
          <div className="bg-white p-5 rounded shadow">
            <div className="flex flex-col justify-center">
              <div>
                <span>Sender Information</span>
                <article className="md:grid grid-cols-2 gap-5 bg-gray-200 p-2 mb-5">
                  <div className="flex flex-col">
                    <label htmlFor="senderFullname">Sender Full Name</label>
                    <input
                      type="text"
                      name="senderFullname"
                      id="senderFullname"
                      value={formik.values.senderFullname}
                      onChange={formik.handleChange("senderFullname")}
                      onBlur={formik.handleBlur("senderFullname")}
                      autoComplete="off"
                    />
                    <div className="error">
                      { formik.touched.senderFullname && formik.errors.senderFullname ? (
                          <div>{formik.errors.senderFullname}</div>
                          ) : null }
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <label htmlFor="senderPhoneNo">Sender Phone Number</label>
                    <input
                      type="text"
                      name="senderPhoneNo"
                      id="senderPhoneNo"
                      autoComplete="off"
                      value={formik.values.senderPhoneNo}
                      onChange={formik.handleChange("senderPhoneNo")}
                      onBlur={formik.handleBlur("senderPhoneNo")}
                      />
                      <div className="error">
                          { formik.touched.senderPhoneNo && formik.errors.senderPhoneNo ? (
                              <div>{formik.errors.senderPhoneNo}</div>
                              ) : null }
                      </div>
                  </div>

                  <div className="flex flex-col">
                    <label htmlFor="senderPhoneNo">Sender Email Address </label>
                    <input
                      type="text"
                      name="senderEmail"
                      id="senderEmail"
                      autoComplete="off"
                      value={formik.values.senderEmail}
                      onChange={formik.handleChange("senderEmail")}
                      onBlur={formik.handleBlur("senderEmail")}
                      />
                      <div className="error">
                          { formik.touched.senderEmail && formik.errors.senderEmail ? (
                              <div>{formik.errors.senderEmail}</div>
                              ) : null }
                      </div>
                  </div>
                </article>
              </div>

              <div>
                <span>Receiver Information</span>            
              <article className="md:grid grid-cols-2 gap-5 bg-gray-200 p-2 mb-5">
                <div className="flex flex-col">
                  <label htmlFor="receiverFullname">Receiver Full Name</label>
                  <input
                     type="text"
                     name="receiverFullname"
                     id="receiverFullname"
                     value={formik.values.receiverFullname}
                     onChange={formik.handleChange("receiverFullname")}
                     onBlur={formik.handleBlur("receiverFullname")}
                    autoComplete="off"
                  />
                  <div className="error">
                    { formik.touched.receiverFullname && formik.errors.receiverFullname ? (
                        <div>{formik.errors.receiverFullname}</div>
                        ) : null }
                  </div>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="receiverPhoneNo">Receiver Phone Number</label>
                  <input
                    type="text"
                    name="receiverPhoneNo"
                    id="receiverPhoneNo"
                    autoComplete="off"
                    value={formik.values.receiverPhoneNo}
                    onChange={formik.handleChange("receiverPhoneNo")}
                    onBlur={formik.handleBlur("receiverPhoneNo")}
                    />
                    <div className="error">
                        { formik.touched.receiverPhoneNo && formik.errors.receiverPhoneNo ? (
                            <div>{formik.errors.receiverPhoneNo}</div>
                            ) : null }
                    </div>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="receiverPhoneNo">Receiver Email Address </label>
                  <input
                    type="text"
                    name="receiverEmail"
                    id="receiverEmail"
                    autoComplete="off"
                    value={formik.values.receiverEmail}
                    onChange={formik.handleChange("receiverEmail")}
                    onBlur={formik.handleBlur("receiverEmail")}
                    />
                    <div className="error">
                        { formik.touched.receiverEmail && formik.errors.receiverEmail ? (
                            <div>{formik.errors.receiverEmail}</div>
                            ) : null }
                    </div>
                </div>
              </article>
              </div>

              <div>
                <span>Shipping Address</span>
                <article className="md:grid grid-cols-2 gap-5 bg-gray-200 p-2">
                <div className="flex flex-col">
                  <label htmlFor="receiverPhoneNo">Country</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    autoComplete="off"
                    value={formik.values.country}
                    onChange={formik.handleChange("country")}
                    onBlur={formik.handleBlur("country")}
                    />
                    <div className="error">
                        { formik.touched.country && formik.errors.country ? (
                            <div>{formik.errors.country}</div>
                            ) : null }
                    </div>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="receiverPhoneNo">State</label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    autoComplete="off"
                    value={formik.values.state}
                    onChange={formik.handleChange("state")}
                    onBlur={formik.handleBlur("state")}
                    />
                    <div className="error">
                        { formik.touched.state && formik.errors.state ? (
                            <div>{formik.errors.state}</div>
                            ) : null }
                    </div>
                </div>
              </article>
                <article className="md:grid grid-cols-3 gap-10 mb-5 bg-gray-200 p-2">
                  <div className="flex flex-col">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="off"
                      value={formik.values.city}
                      onChange={formik.handleChange("city")}
                      onBlur={formik.handleBlur("city")}
                      />
                      <div className="error">
                          { formik.touched.city && formik.errors.city ? (
                              <div>{formik.errors.city}</div>
                              ) : null }
                      </div>
                  </div>

                  <div className="flex flex-col">
                  <label htmlFor="street">Street</label>
                  <input
                    type="text"
                    name="street"
                    id="street"
                    autoComplete="off"
                    value={formik.values.street}
                    onChange={formik.handleChange("street")}
                    onBlur={formik.handleBlur("street")}
                    />
                    <div className="error">
                        { formik.touched.street && formik.errors.street ? (
                            <div>{formik.errors.street}</div>
                            ) : null }
                    </div>
                </div>

                  <div className="flex flex-col">
                    <label htmlFor="receiverPhoneNo">Zip Code</label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      autoComplete="off"
                      value={formik.values.zip}
                      onChange={formik.handleChange("zip")}
                      onBlur={formik.handleBlur("zip")}
                      />
                      <div className="error">
                          { formik.touched.zip && formik.errors.zip ? (
                              <div>{formik.errors.zip}</div>
                              ) : null }
                      </div>
                    </div>
                  </article>
                </div>            

              <div>
                <span>Invoice Detail</span>
                <article className="md:grid grid-cols-3 gap-10 mb-5 bg-gray-200 p-2">
                    <div className="flex flex-col">
                      <label htmlFor="receiverPhoneNo">Invoice Number</label>
                      <input
                        type="text"
                        name="invoiceNo"
                        id="invoiceNo"
                        autoComplete="off"
                        value={formik.values.invoiceNo}
                        onChange={formik.handleChange("invoiceNo")}
                        onBlur={formik.handleBlur("invoiceNo")}
                        />
                        <div className="error">
                            { formik.touched.invoiceNo && formik.errors.invoiceNo ? (
                                <div>{formik.errors.invoiceNo}</div>
                                ) : null }
                        </div>
                    </div>

                    <div className="flex flex-col">
                      <label htmlFor="invoiceDate">Invoice Date</label>
                      <input
                        type="date"
                        name="invoiceDate"
                        id="invoiceDate"
                        value={formik.values.invoiceDate}
                        onChange={formik.handleChange("invoiceDate")}
                        onBlur={formik.handleBlur("invoiceDate")}
                        />
                        <div className="error">
                            { formik.touched.invoiceDate && formik.errors.invoiceDate ? (
                                <div>{formik.errors.invoiceDate}</div>
                                ) : null }
                        </div>
                    </div>
                </article>
              </div>

              <div>
                <span>Shipment Description</span>
                <article className="mb-5 bg-gray-200 p-2">
                  <TableForm
                    description={description}
                    setDescription={setDescription}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    price={price}
                    setPrice={setPrice}
                    amount={amount}
                    setAmount={setAmount}
                    list={list}
                    setList={setList}
                    total={total}
                    setTotal={setTotal}
                    />
                </article>
              </div>

              <label htmlFor="notes">Additional Notes</label>
              <textarea
                name="notes"
                id="notes"
                cols="30"
                rows="10"
                style={{resize: "none"}}
                placeholder="Additional notes regarding shipment"
                value={formik.values.notes}
                onChange={formik.handleChange("notes")}
                onBlur={formik.handleBlur("notes")}
                />
                <div className="error">
                    { formik.touched.notes && formik.errors.notes ? (
                        <div>{formik.errors.notes}</div>
                        ) : null }
                </div>
            </div>
          </div>
          <article className="mt-5">
          </article>
        </section>

        {/* Invoice Preview */}

        <div className="invoice__preview bg-white rounded" >
          <ReactToPrint
            trigger={() => (
              <button className="bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300">
                Print / Download
              </button>
            )}
            content={() => componentRef.current}
            pageStyle={{ padding: "50px", backgroundColor: "inherit" }}
          />
          <div ref={componentRef} className="p-5">
            <Header  />
              <InvoiceData 
                invoiceNumber={values.invoiceNo}
                invoiceDate={values.invoiceDate}
              />

            <SenderDetails 
              name={values.senderFullname}
              phoneNo={values.senderPhoneNo}
              email={values.senderEmail}
              />

            <ReceiverDetails
              name={values.receiverFullname}
              phoneNo={values.receiverPhoneNo}
              email={values.receiverEmail}
            />

            <ShipmentInformation
              country={values.country}
              state={values.state}
              city={values.city}
              street={values.street}
              zip={values.zip}
            />

            <Table
              description={description}
              quantity={quantity}
              price={price}
              amount={amount}
              list={list}
              setList={setList}
              total={total}
              setTotal={setTotal}
            />

            <Notes notes={values.notes} />

            <Footer
              name={values.senderFullname}
              address={values.country}
              website={values.city}
              email={values.senderEmail}
              phone={values.senderPhoneNo}
              bankAccount={values.invoiceNo}
              bankName={values.invoiceDate}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default Invoice;
