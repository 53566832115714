export default function Footer({
}) {
  return (
    <>
      <footer className="footer border-t-2 border-gray-300 pt-5 font-semibold">
          <span className="text-sm">I/We hereby certify that the information on this invoice is true and correct and that the contents of this
                shipment are as stated above.</span>
          <h2 className="mt-10">Signature: ____________________</h2>
      </footer>
    </>
  )
}
